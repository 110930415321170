import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from '@/core/libs/utils';

const buttonVariants = cva(
  'inline-flex items-center justify-center whitespace-nowrap rounded-full text-base font-medium transition-colors focus-visible:outline-none disabled:pointer-events-none disabled:opacity-20',
  {
    variants: {
      appearance: {
        filled: '',
        outline: 'border',
        subtle: '',
        input: 'border-b rounded-none',
        /** @deprecated use ButtonLink instead */
        link: 'rounded-none bg-transparent underline-offset-2 p-1',
      },
      color: {
        primary: '',
        secondary: '',
        neutral:
          'text-secondary-900 bg-neutral-100 hover:bg-neutral-200 active:bg-neutral-200',
        danger:
          'text-danger-100 bg-danger-dark hover:bg-danger-main active:bg-danger-main',
        warning:
          'text-secondary-900 bg-warning-main hover:bg-warning-dark active:bg-warning-dark',
      },
      mode: {
        light:
          'ring-offset-secondary-50 focus-visible:ring focus-visible:ring-offset-2 focus-visible:ring-secondary-900',
        dark: 'ring-offset-secondary-900 focus-visible:ring focus-visible:ring-offset-2 focus-visible:ring-secondary-50',
      },
      size: {
        sm: 'h-9 px-8 py-2 text-sm',
        md: 'h-11 px-10 py-3 text-sm',
        lg: 'h-14 px-12 py-4',
      },
    },
    compoundVariants: [
      {
        appearance: 'filled',
        color: 'secondary',
        mode: 'light',
        className:
          'bg-secondary-900 hover:bg-secondary-800 text-secondary-50 active:bg-secondary-800',
      },
      {
        appearance: 'filled',
        color: 'secondary',
        mode: 'dark',
        className:
          'bg-secondary-50 hover:bg-secondary-200 text-secondary-900 active:bg-secondary-200',
      },
      {
        appearance: 'filled',
        color: 'primary',
        mode: 'light',
        className:
          'bg-primary-500 text-secondary-900 hover:bg-primary-300 active:bg-primary-300',
      },
      {
        appearance: 'filled',
        color: 'primary',
        mode: 'dark',
        className:
          'bg-primary-500 text-secondary-900 hover:bg-primary-300 active:bg-primary-300',
      },
      {
        appearance: 'outline',
        color: 'secondary',
        mode: 'light',
        className:
          'border-secondary-900 text-secondary-900 hover:bg-secondary-900 hover:text-secondary-50 active:bg-secondary-900 active:text-secondary-50',
      },
      {
        appearance: 'outline',
        color: 'secondary',
        mode: 'dark',
        className:
          'border-secondary-50 text-secondary-50 hover:bg-secondary-50 hover:text-secondary-900 active:bg-secondary-50 active:text-secondary-900',
      },
      {
        appearance: 'subtle',
        color: 'secondary',
        mode: 'light',
        className:
          'bg-transparent text-secondary-900 hover:bg-secondary-50 active:bg-secondary-50',
      },
      {
        appearance: 'subtle',
        color: 'secondary',
        mode: 'dark',
        className:
          'bg-transparent text-secondary-50 hover:bg-secondary-800 active:bg-secondary-800',
      },
      {
        appearance: 'input',
        mode: 'light',
        className:
          'px-3 text-black border-neutral-400 bg-neutral-50 hover:border-b-primary-700 focus-visible:border-b-primary-700',
      },
      {
        appearance: 'input',
        mode: 'dark',
        className:
          'px-3 border-b-0 text-secondary-50 bg-secondary-800 hover:border-b-primary-500 focus-visible:border-b-primary-500',
      },
      {
        appearance: 'link',
        mode: 'light',
        className:
          'text-primary-700 hover:text-primary-600 visited:text-primary-600',
      },
      {
        appearance: 'link',
        mode: 'dark',
        className:
          'text-primary-300 hover:text-primary-200 visited:text-primary-200',
      },
      {
        appearance: 'link',
        size: 'lg',
        className: 'h-auto text-base font-medium',
      },
      {
        appearance: 'link',
        size: 'md',
        className: 'h-auto text-sm font-medium',
      },
      {
        appearance: 'link',
        size: 'sm',
        className: 'h-auto text-xs font-medium',
      },
    ],
    defaultVariants: {
      appearance: 'filled',
      color: 'secondary',
      mode: 'light',
      size: 'md',
    },
  },
);

export interface ButtonProps
  extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'color'>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    { className, appearance, size, color, mode, asChild = false, ...props },
    ref,
  ) => {
    const Comp = asChild ? Slot : 'button';
    return (
      <Comp
        className={cn(
          buttonVariants({ appearance, size, color, mode, className }),
        )}
        ref={ref}
        {...props}
      />
    );
  },
);
Button.displayName = 'Button';

export { Button, buttonVariants };
