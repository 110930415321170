import { useState } from 'react';

import { useAuthenticationContext } from '@/core/context/AuthenticationContext';
import { errorHandler } from '@/core/libs/error-handler';
import {
  AdminService,
  ProjectRequest,
  ProjectsService,
  User,
} from '@/generated/api';

export interface UseCreateRFQ {
  createRFQ: (rfq: ProjectRequest) => Promise<void>;
  loading: boolean;
}

export function useCreateRFQ(): UseCreateRFQ {
  const [loading, setLoading] = useState<boolean>(false);
  const { currentUser } = useAuthenticationContext();

  async function createRFQ(rfq: ProjectRequest): Promise<void> {
    try {
      setLoading(true);
      const isAdmin = currentUser?.role === User.role._0;
      const method = isAdmin
        ? AdminService.projectsAdminControllerCreate
        : ProjectsService.projectsControllerCreate;
      await method({ ...rfq, projectId: null });
    } catch (error) {
      errorHandler.capture(error);
      throw error;
    } finally {
      setLoading(false);
    }
  }

  return { createRFQ, loading };
}
