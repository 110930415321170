/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import type { Company } from '../models/Company';
import type { FeedFilterRangesDto } from '../models/FeedFilterRangesDto';
import type { FeedStatsDto } from '../models/FeedStatsDto';
import type { PaginatedResult } from '../models/PaginatedResult';
import type { Project } from '../models/Project';
import type { ProjectRequest } from '../models/ProjectRequest';
import type { ProjectUpdateRequest } from '../models/ProjectUpdateRequest';
import type { SearchMachineDto } from '../models/SearchMachineDto';

export class ProjectsService {
  /**
   * @param country
   * @returns FeedStatsDto
   * @throws ApiError
   */
  public static projectsControllerFeedStats(
    country?: string,
  ): CancelablePromise<FeedStatsDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/projects/feed-stats',
      query: {
        country: country,
      },
    });
  }

  /**
   * @param country
   * @returns FeedStatsDto
   * @throws ApiError
   */
  public static projectsControllerFeedStatsPrivate(
    country?: string,
  ): CancelablePromise<FeedStatsDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/projects/feed-stats-private',
      query: {
        country: country,
      },
    });
  }

  /**
   * @param requestBody
   * @returns Project
   * @throws ApiError
   */
  public static projectsControllerCreate(
    requestBody: ProjectRequest,
  ): CancelablePromise<Project> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/projects',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns Project
   * @throws ApiError
   */
  public static projectsControllerFindAllOfUser(): CancelablePromise<
    Array<Project>
  > {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/projects',
    });
  }

  /**
   * @param id
   * @param requestBody
   * @returns Project
   * @throws ApiError
   */
  public static projectsControllerUpdate(
    id: number,
    requestBody: ProjectUpdateRequest,
  ): CancelablePromise<Project> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/projects/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param id
   * @param isEdit
   * @returns Project
   * @throws ApiError
   */
  public static projectsControllerFind(
    id: number,
    isEdit?: boolean,
  ): CancelablePromise<Project> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/projects/{id}',
      path: {
        id: id,
      },
      query: {
        isEdit: isEdit,
      },
    });
  }

  /**
   * @returns FeedFilterRangesDto
   * @throws ApiError
   */
  public static projectsControllerGetFeedFilterRanges(): CancelablePromise<FeedFilterRangesDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/projects/filter-ranges',
    });
  }

  /**
   * @param page
   * @param limit
   * @param withExpired
   * @param sort
   * @param minWorkpieces
   * @param maxWorkpieces
   * @param minLotSizes
   * @param maxLotSizes
   * @param technologyIds
   * @param materialIds
   * @param certificationIds
   * @param incoterms
   * @param countryCodes
   * @param machines
   * @returns any
   * @throws ApiError
   */
  public static projectsControllerPublicPaginatedSearch(
    page: number = 1,
    limit: number = 10,
    withExpired?: boolean,
    sort?: 'newest' | 'deadline',
    minWorkpieces?: number,
    maxWorkpieces?: number,
    minLotSizes?: number,
    maxLotSizes?: number,
    technologyIds?: Array<string>,
    materialIds?: Array<string>,
    certificationIds?: Array<string>,
    incoterms?: Array<string>,
    countryCodes?: Array<string>,
    machines?: Array<SearchMachineDto>,
  ): CancelablePromise<PaginatedResult> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/projects/public-search',
      query: {
        page: page,
        limit: limit,
        'with-expired': withExpired,
        sort: sort,
        minWorkpieces: minWorkpieces,
        maxWorkpieces: maxWorkpieces,
        minLotSizes: minLotSizes,
        maxLotSizes: maxLotSizes,
        technologyIds: technologyIds,
        materialIds: materialIds,
        certificationIds: certificationIds,
        incoterms: incoterms,
        countryCodes: countryCodes,
        machines: machines,
      },
    });
  }

  /**
   * @param page
   * @param limit
   * @param withExpired
   * @param sort
   * @param minWorkpieces
   * @param maxWorkpieces
   * @param minLotSizes
   * @param maxLotSizes
   * @param technologyIds
   * @param materialIds
   * @param certificationIds
   * @param incoterms
   * @param countryCodes
   * @param machines
   * @returns any
   * @throws ApiError
   */
  public static projectsControllerPaginatedSearch(
    page: number = 1,
    limit: number = 10,
    withExpired?: boolean,
    sort?: 'newest' | 'deadline',
    minWorkpieces?: number,
    maxWorkpieces?: number,
    minLotSizes?: number,
    maxLotSizes?: number,
    technologyIds?: Array<string>,
    materialIds?: Array<string>,
    certificationIds?: Array<string>,
    incoterms?: Array<string>,
    countryCodes?: Array<string>,
    machines?: Array<SearchMachineDto>,
  ): CancelablePromise<PaginatedResult> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/projects/search',
      query: {
        page: page,
        limit: limit,
        'with-expired': withExpired,
        sort: sort,
        minWorkpieces: minWorkpieces,
        maxWorkpieces: maxWorkpieces,
        minLotSizes: minLotSizes,
        maxLotSizes: maxLotSizes,
        technologyIds: technologyIds,
        materialIds: materialIds,
        certificationIds: certificationIds,
        incoterms: incoterms,
        countryCodes: countryCodes,
        machines: machines,
      },
    });
  }

  /**
   * @param id
   * @returns Project
   * @throws ApiError
   */
  public static projectsControllerFindProjectWithInvitations(
    id: number,
  ): CancelablePromise<Project> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/projects/{id}/invitations',
      path: {
        id: id,
      },
    });
  }

  /**
   * @param projectId
   * @param status
   * @returns any
   * @throws ApiError
   */
  public static projectsControllerChangeStatus(
    projectId: number,
    status: string,
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/projects/{projectId}/{status}',
      path: {
        projectId: projectId,
        status: status,
      },
    });
  }

  /**
   * @param rfqId
   * @returns Company
   * @throws ApiError
   */
  public static projectsControllerFindBuyer(
    rfqId: number,
  ): CancelablePromise<Company> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/projects/{rfqId}/buyer-details',
      path: {
        rfqId: rfqId,
      },
    });
  }
}
